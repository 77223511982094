.about-root {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-title {
  font-size: 1.5em;
  font-weight: 500;
  margin-bottom: 0;
}

.about-content {
  font-size: 1.25em;
}

.personal-container {
  text-align: right;
}

.content-label {
  font-weight: bolder;
}

.content-paragraph {
  margin-bottom: 2em;
  padding-bottom: 2em;
  border-bottom: var(--color-primary) dotted 1px;
}

.about-skills {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.about-skill {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-left: 0.25em;
}

.about-skill:hover>.about-skill-label {
  color: var(--color-primary);
}

.about-skill-icon {
  font-size: 2em;
  margin: 0 0.25em 0.25em 0.25em;
}

.about-skill-label {
  position: absolute;
  display: block;
  font-size: 0.75em;
  color: transparent;
  transition: color 250ms ease-in-out, width 250ms ease-in-out;
}

@media (max-aspect-ratio:1/1) and (min-aspect-ratio: 1/2) {
  .about-title {
    font-size: 1.25em;
  }

  .about-content {
    font-size: 1em;
  }
}

@media (max-aspect-ratio:1/2) {
  .about-title {
    font-size: 1.25em;
  }

  .about-content {
    font-size: 1em;
  }
}