.resume-root {
  width: 95%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1em;
}

.resume-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1em;
}

.resume-title {
  font-size: 1.5em;
  font-weight: 500;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;
}

.download-button {
  height: 3em;
  width: 8em;
  font-size: 1em;
  padding: 0.5em;
  border: var(--color-primary) solid 2px;
  background-color: var(--background-color-secondary);
  color: var(--color-primary);
  border-radius: 0.5em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: transform 250ms ease-in-out, box-shadow 250ms ease-in-out;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

.download-button:hover {
  box-shadow: 2px 2px var(--color-primary);
  transform: translateY(-0.1em);
}

.download-button:active {
  box-shadow: 0px 0px;
  transform: translateY(0em);
}

.resume-section-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1.25em;
  margin-bottom: 2em;
}

.resume-section-title {
  width: 25%;
  height: 100%;
  border-right: var(--color-primary) solid 1px;
  text-align: end;
  padding-right: 2em;
  font-weight: bolder;
}

.resume-section-content {
  width: 70%;
  padding-left: 2em;
}

.resume-section-header {
  font-weight: bolder;
  margin: 0;
}

.resume-section-separated {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  margin-bottom: 1em;
}

.resume-section-item {
  margin-bottom: 2em;
}

.resume-section-item-last {
  margin-bottom: -1em;
}


@media (max-aspect-ratio:3/2) {
  .resume-header {
    font-size: 0.9em;
  }

  .resume-section-container {
    font-size: 1em;
    flex-direction: column;
    margin-bottom: 2.5em;
  }

  .resume-section-title {
    width: 30%;
    border-right: none;
    border-bottom: var(--color-primary) solid 1px;
    margin-bottom: 1em;
    text-align: start;
  }

  .resume-section-content {
    width: 100%;
    padding-left: 0;
  }
}

@media (max-aspect-ratio:2/3) {
  .download-button-text {
    display: none;
  }

  .download-button {
    width: 3em;
  }
}


/* @media (max-width: 900px) {
  .resume-section-container {
    flex-direction: column;
    margin-bottom: 2.5em;
  }

  .resume-section-title {
    width: 30%;
    border-right: none;
    border-bottom: var(--color-primary) solid 1px;
    margin-bottom: 1em;
    text-align: start;
  }

  .resume-section-content {
    width: 100%;
    padding-left: 0;
  }
} */