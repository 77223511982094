.projects-root {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 1.5em;
  margin: 1em;
}

.projects-header {
  margin-top: 0.35em;
}

.projects-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  flex-wrap: wrap;
}

.project-container {
  width: 40%;
  background-color: var(--background-color-secondary);
  border: 0.1em black solid;
  border-radius: 1em;
  margin-bottom: 1em;
  padding: 1em 1em 0 1em;
  transition: background-color 500ms ease-in-out, color 500ms ease-in-out, border-color 500ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.project-container:hover {
  background-color: var(--color-secondary);
  color: var(--background-color-secondary);
  border-color: var(--color-secondary);
}

.project-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.project-name {
  font-size: 0.9em;
  margin: 0;
  justify-content: center;
  align-items: center;
}

.project-links {
  display: flex;
  flex-direction: row;
}

.project-link-icon {
  margin-left: 0.25em;
  font-size: 1.1em;
}

.project-link-icon:hover {
  cursor: pointer;
}

.project-description {
  flex-grow: 1;
  font-size: 0.75em;
  font-weight: 100;
  min-height: 10em;
}

.project-skills {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.project-skill-icon {
  margin-left: 0.25em;
}

@media (max-aspect-ratio: 4/3) and (min-aspect-ratio: 1/2) {
  .project-container {
    width: 90%;
  }
}

@media (max-aspect-ratio: 1/2) {
  .project-container {
    width: 100%;
  }
}