.root {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.header-layout {
  height: 4.25em;
  width: 100%;
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  z-index: 1;
}

.body-layout {
  min-height: 100%;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.footer-layout {
  width: 100%;
  bottom: 0;
}

@media (min-aspect-ratio: 2/1) {
  .body-layout {
    width: 60%;
  }
}

@media (max-aspect-ratio:2/1) and (min-aspect-ratio: 1/1) {
  .body-layout {
    width: 67%;
  }
}

@media (max-aspect-ratio: 1/1) {
  .body-layout {
    width: 90%;
  }
}

@media (min-width: 3000px) {
  .root {
    font-size: 150%;
  }
}

@media (min-width: 4000px) {
  .root {
    font-size: 200%;
  }
}