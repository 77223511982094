.footer-root {
  background-color: var(--background-color-secondary);
  height: 2em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: #eaeaea solid 1px;
}

.footer-text {
  font-size: 0.75em;
  color: #aaa;
  padding-top: 0.5em;
  margin-top: 0px;
  margin-bottom: auto;
  padding-left: 1.5em;
}

.footer-links {
  height: 100%;
  font-size: 1.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 0.5em;
}

.social-link {
  height: 100%;
  color: #aaa;
  display: flex;
  align-items: center;
  margin-left: 0.1em;
  transition: color 250ms ease-in-out;
}

.social-link:hover {
  color: var(--color-primary);
}