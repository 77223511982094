.contact-root {
  width: 95%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1em;
}

.contact-title {
  font-size: 1.5em;
  font-weight: 500;
  margin: 0;
}

.contact-text {
  font-size: 1.2em;
  max-width: 30em;
  width: 100%;
  text-align: center;
}

.contact-text-link {
  color: var(--color-secondary);
  text-decoration: none;
}

.contact-text-link:hover {
  text-decoration: underline;
}

.contact-form {
  max-width: 25em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.form-label {
  font-size: 1.2em;
  font-weight: bolder;
  margin: 0.25em;
  margin-bottom: 0.1em;
}

.form-textbox {
  width: 95%;
  font-size: 1em;
  border: var(--color-primary) solid 2px;
  border-radius: 5px;
  height: 2em;
  background-color: var(--background-color-secondary);
  padding: 0.25em;
  padding-left: 0.75em;
  margin-bottom: 2em;
  transition: border 250ms step-start;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

.form-textbox:focus {
  outline: none;
  border: var(--color-secondary) solid 2px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-textarea {
  width: 95%;
  height: 5em;
  max-width: 95%;
  min-width: 95%;
  min-height: 5em;
  padding-top: 0.75em;
  margin-bottom: 2em;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

.form-textbox-error {
  border: var(--error-color-primary) solid 2px;
  margin-bottom: 0em;
}

.form-textbox-error:focus {
  border: var(--error-color-secondary) solid 2px;
}

.error-message {
  font-size: 1em;
  margin: 0.25em;
  color: var(--error-color-primary);
  font-weight: bolder;
}

.error-message-general {
  font-size: 1em;
  margin: 0.25em;
  margin-top: -1.75em;
  color: var(--error-color-primary);
  font-weight: bolder;
}

.submit-button {
  width: 45%;
  height: 3em;
  font-size: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-top: 1em;
  margin-right: 0;
  margin-left: auto;
  border: var(--color-primary) solid 2px;
  background-color: var(--background-color-secondary);
  color: var(--color-primary);
  border-radius: 0.5em;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  transition: transform 250ms ease-in-out, box-shadow 250ms ease-in-out;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
}

.submit-button:hover {
  box-shadow: 2px 2px var(--color-primary);
  transform: translateY(-0.1em);
}

.submit-button:focus {
  box-shadow: 0px 0px;
  transform: translateY(0em);
  border-color: var(--color-primary);
  color: var(--color-primary);
}

.submit-button-disabled {
  color: var(--color-primary);
  cursor: default;
}

.submit-button-disabled:hover {
  box-shadow: none;
  transform: none;
  cursor: default;
}

.submit-button-icon {
  width: 1.5em;
  height: 1.5em;
}

@media (max-aspect-ratio:1/2) {
  .contact-title {
    font-size: 1.25em;
  }

  .contact-text {
    font-size: 1em;
  }

  .form-label {
    font-size: 1em;
  }

  .form-textbox {
    font-size: 0.9em;
  }

  .error-message {
    font-size: 0.9em;
  }

  .submit-button {
    width: 90%;
    font-size: 0.9em;
    margin-right: auto;
  }
}